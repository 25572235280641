import React from "react";
import { MdOutlineDateRange } from "react-icons/md";
import { FiDownload } from "react-icons/fi";
import styles from "./StateMent.module.css";

const SingleStateMent = ({
  status,
  wallet: toAddress,
  timestamp: date,
  amount: amount,
  invoice,
}) => {
  // formatin number
  // const formattedNumber = (number) => {
  //   return number.toLocaleString("en-US", {
  //     style: "currency",
  //     currency: "USD",
  //     minimumFractionDigits: 2,
  //   });
  // };
  return (
    <tr className={styles.row}>
      <td className={styles.item}>
        {toAddress.slice(0, 7) + "...." + toAddress.slice(-6)}
      </td>
      <td className={styles.item}>
        {" "}
        <div className={styles.flex}>
          <MdOutlineDateRange className={styles.date} />{" "}
          {new Date(date).toLocaleString("en-GB")}
        </div>
      </td>
      <td className={styles.item}>{amount} UNS</td>
      <td
        className={[
          styles.item,
          status.toLowerCase() === "completed"
            ? styles.complete
            : status.toLowerCase() === "pending"
              ? styles.pending
              : status.toLowerCase() === "failed"
                ? styles.failed
                : "",
        ].join(" ")}
      >
        {status.toUpperCase()}
      </td>
      {/* <td className={styles.item}>
        <FiDownload className={styles.downloadIcon} />
      </td> */}
    </tr>
  );
};

export default SingleStateMent;
