import React, { useState } from "react";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import styles from "./WithDrawAmount.module.css";
import { base } from "../../../images/image";
import axios from "axios";
import Cookies from "js-cookie";
const WithDrawAmount = ({ setBalanceChange }) => {
  const [withDrawalAmount, setWithDrawalAmount] = useState("");
  const [walletAddress, setWalletAddress] = useState("");

  const allCoins = [
    { icon: "https://uns.technology/favicon/apple-touch-icon.png", coinName: "UNS Token" },
  ];
  const allNetworks = ["BEP-20"];
  const [currentCoin, setCurrentCoin] = useState(allCoins[0]);
  const [coinDropDown, setCoinDropDown] = useState(false);
  const [currentNetwork, setCurrentNetwork] = useState("BEP-20");
  const [networkDropDown, setNetworkDropDown] = useState(false);



  async function withdraw() {
    let api = "https://community-api.uns.technology/account/withdraw";
    let body = {
      amount: withDrawalAmount.trim(),
      wallet: walletAddress,
    };

    let { data } = await axios.post(api, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      }
    })

    alert(data.message);
    setBalanceChange(Math.random());
  }

  const handleKeyDown = (event) => {
    if (
      !(
        /[0-9]/.test(event.key) ||
        event.key === "Backspace" ||
        event.key === "ArrowUp" ||
        event.key === "ArrowDown" ||
        event.key === "Enter"
      )
    ) {
      event.preventDefault();
    }
  };
  return (
    <div className={styles.wrapper}>
      <div>
        <h4 className={styles.title}>Withdrawal</h4>
        <p className={styles.tagline}>
          {/* If you need more info, please check out{" "} */}
          <a
            href="#/"
            target="_blank"
            rel="noreferrer"
            className={styles.helpRoute}
          >
            {/* Help Page */}
          </a>{" "}
          {/* . */}
        </p>
      </div>

      <div>
        <div className={styles.spaceBetween}>
          <p className={styles.label}>Withdrawal Amount</p>
          <p className={[styles.label, styles.highlight].join(" ")}>
            Minimum Withdrawal Amount: 5 UNS
          </p>
        </div>
        <input
          type="number"
          name="withdrawalAmount"
          placeholder="Enter your amount to withdraw"
          className={styles.input}
          onKeyDown={handleKeyDown}
          value={withDrawalAmount}
          onChange={(e) => setWithDrawalAmount(e.target.value)}
        />
      </div>
      {/* <div>
        <p className={styles.label}>Select Coin & Network</p>
        <div className={styles.dropDownWrapper}>
          <div
            className={[styles.dropDownContainer, styles.coinsContainer].join(
              " "
            )}
          >
            <div
              className={styles.itemWrapper}
              onClick={() => setCoinDropDown((prev) => !prev)}
            >
              <img src="https://uns.technology/favicon/apple-touch-icon.png" width={20} alt="#" className={styles.icon} />
              <p className={styles.coinName}>{currentCoin.coinName}</p>
            </div>
            {coinDropDown ? (
              <BiChevronUp
                className={styles.arrow}
                onClick={() => setCoinDropDown((prev) => !prev)}
              />
            ) : (
              <BiChevronDown
                className={styles.arrow}
                onClick={() => setCoinDropDown((prev) => !prev)}
              />
            )}

            {coinDropDown && (
              <div className={styles.dropDownItems}>
                {allCoins.map((el, i) => (
                  <div
                    className={styles.itemWrapper}
                    key={i}
                    onClick={() => {
                      setCurrentCoin({ ...el });
                      setCoinDropDown(false);
                    }}
                  >
                    <img src={el.icon} alt="#" className={styles.icon} />
                    <p className={styles.coinName}>{el.coinName}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div
            className={[
              styles.dropDownContainer,
              styles.networksContainer,
            ].join(" ")}
          >
            <p
              className={styles.networkName}
              onClick={() => setNetworkDropDown((prev) => !prev)}
            >
              <span className={styles.prefix}>Network: </span> {currentNetwork}
            </p>

            {networkDropDown ? (
              <BiChevronUp
                className={styles.arrow}
                onClick={() => setNetworkDropDown(false)}
              />
            ) : (
              <BiChevronDown
                className={styles.arrow}
                onClick={() => setNetworkDropDown(true)}
              />
            )}

            {networkDropDown && (
              <div className={styles.dropDownItems}>
                {allNetworks.map((el, i) => (
                  <p
                    className={styles.coinName}
                    onClick={() => {
                      setCurrentNetwork(el);
                      setNetworkDropDown(false);
                    }}
                    key={i}
                  >
                    {el}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
      </div> */}
      <div>
        <p className={styles.label}>Wallet Address</p>
        <input
          type="text"
          name="walletAddress"
          placeholder="Enter your Address Wallet"
          className={styles.input}
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
        />
      </div>
      <button
        onClick={withdraw}
        className={styles.button}>Withdraw</button>
    </div>
  );
};

export default WithDrawAmount;
