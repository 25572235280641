import React, { useEffect, useState } from "react";

import styles from "./StateMent.module.css";
import SingleStateMent from "./SingleStateMent";
import axios from "axios";
import Cookies from "js-cookie";
const LeaderBoard = () => {
  const [data, setData] = useState([]);

  let fetchData = async () => {
    let api = "https://community-api.uns.technology/account/withdrawals";
    let { data } = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });

    setData(data.withdrawals);
  };

  useEffect(() => {
    fetchData();
  }, [data]);

  // const data = [
  //   {
  //     toAddress: "fftt456765g ... 8306767",
  //     date: "Jul 29, 2023",
  //     amount: 308.55,
  //     invoice: "#",
  //   },
  //   {
  //     toAddress: "fftt456765g ... 8306767",
  //     date: "Jul 29, 2023",
  //     amount: 2589.2,
  //     invoice: "#",
  //   },
  //   {
  //     toAddress: "fftt456765g ... 8306767",
  //     date: "Jul 25, 2023",
  //     amount: 24042.76,
  //     invoice: "#",
  //   },
  //   {
  //     toAddress: "fftt456765g ... 8306767",
  //     date: "Jul 24, 2023",
  //     amount: 0.14,
  //     invoice: "#",
  //   },
  //   {
  //     toAddress: "fftt456765g ... 8306767",
  //     date: "Jul 20, 2023",
  //     amount: 0.14,
  //     invoice: "#",
  //   },
  //   {
  //     toAddress: "fftt456765g ... 8306767",
  //     date: "Jul 24, 2023",
  //     amount: 53.73,
  //     invoice: "#",
  //   },
  //   {
  //     toAddress: "fftt456765g ... 8306767",
  //     date: "Jul 25, 2023",
  //     amount: 24042.76,
  //     invoice: "#",
  //   },
  //   {
  //     toAddress: "fftt456765g ... 8306767",
  //     date: "Jul 24, 2023",
  //     amount: 0.14,
  //     invoice: "#",
  //   },
  //   {
  //     toAddress: "fftt456765g ... 8306767",
  //     date: "Jul 20, 2023",
  //     amount: 0.14,
  //     invoice: "#",
  //   },
  //   {
  //     toAddress: "fftt456765g ... 8306767",
  //     date: "Jul 24, 2023",
  //     amount: 53.73,
  //     invoice: "#",
  //   },
  // ];
  return (
    <div className={styles.wrapper} style={{ overflowX: "auto" }}>
      <h4 className={styles.title}>Withdrawal History</h4>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <tbody>
            <tr className={[styles.row, styles.header].join(" ")}>
              <th className={styles.heading}>Address</th>
              <th className={styles.heading}>Date</th>
              <th className={styles.heading}>Amount</th>
              <th className={styles.heading}>Status </th>
            </tr>

            {data.map((el, index) => (
              <SingleStateMent {...el} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default LeaderBoard;
