import React from "react";
import styles from "./WithDrawal.module.css";
import WithDrawAmount from "../../Component/WithDrawal/WithDrawAmount/WithDrawAmount";
import StateMent from "../../Component/WithDrawal/StateMent/StateMent";
import BalanceAndWithdraw from "../../Component/WithDrawal/BalanceAndWithdraw/BalanceAndWithdraw";
import MerchantInfo from "../../Component/WithDrawal/MerchantInfo/MerchantInfo";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

const WithDrawal = ({ uuser }) => {

  const [user, setUser] = useState({});
  const [balanceChange, setBalanceChange] = useState(0);


  let navigate = useNavigate();
  let token = Cookies.get("token");
  if (!token) {
    navigate("/login");
  }

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getProfile = async () => {
    const api = "https://community-api.uns.technology/account";
    try {
      const { data } = await axios.get(api, config);
      console.log(data);
      let user = data.user;
      // setuUser(user);
      setUser(user);
    } catch (error) {
      console.log(error);
      navigate("/login");
    }
  };

  useEffect(() => {
    getProfile();
  }, [balanceChange]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.item}>
        <BalanceAndWithdraw uuser={user} />

        <StateMent />
      </div>
      <div className={styles.item}>
        <WithDrawAmount setBalanceChange={setBalanceChange} />
        {/* <MerchantInfo /> */}
      </div>
    </div>
  );
};

export default WithDrawal;
