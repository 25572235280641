import React from "react";
import LeaderBoard from "../../Component/LeaderBoard/LeaderBoard";

const LeaderBoardPage = () => {
  return (
    <div>
      <LeaderBoard />
    </div>
  );
};

export default LeaderBoardPage;
