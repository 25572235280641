import React from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import styles from "./Sidebar.module.css";
import {
  defaultIcon,
  activeDefaultIcon,
  eCommerce,
  activeEcommerce,
  projects,
  activeProjects,
  onlineCourses,
  acitveOnlineCourses,
  userProfile,
  activeUserProfile,
  account,
  activeAccount,
  corporate,
  activeCorporate,
  blog,
  activeBlog,
  social,
  activeSocial,
} from "../../../images/image";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";

const Sidebar = ({ sidebar, setSidebar }) => {
  const navItems = [
    {
      icons: [defaultIcon, activeDefaultIcon],
      navItem: "Dashboard",
      to: "/dashboard",
    },
    {
      icons: [eCommerce, activeEcommerce],
      navItem: "Withdraw",
      to: "/withdraw",
    },

    {
      icons: [onlineCourses, acitveOnlineCourses],
      navItem: "Leaderboard",
      to: "/leaderboard",
    },

    // {
    //   icons: [userProfile, activeUserProfile],
    //   navItem: "User Profile",
    //   to: "/userProfile",
    // },
    // { icons: [account, activeAccount], navItem: "Account", to: "/account" },
    // {
    //   icons: [corporate, activeCorporate],
    //   navItem: "Corporate",
    //   to: "/corporate",
    // },
    // { icons: [blog, activeBlog], navItem: "Blog", to: "/blog" },
    // { icons: [social, activeSocial], navItem: "Social", to: "/Social" },
  ];

  return (
    <section
      className={[styles.sidebar, sidebar && styles.showSidebar].join(" ")}
    >
      <div className={styles.navItems}>
        {navItems.map((el, i) => (
          <NavLink
            to={el.to}
            key={i}
            className={({ isActive }) =>
              isActive
                ? [styles.navItem, styles.navActive].join(" ")
                : styles.navItem
            }
            onClick={() => setSidebar((prev) => !prev)}
          >
            {({ isActive, isPending }) => {
              return (
                <>
                  <img
                    src={isActive ? el.icons[1] : el.icons[0]}
                    alt="#"
                    className={styles.navIcon}
                  />
                  <span>{el.navItem}</span>
                </>
              );
            }}
          </NavLink>
        ))}
      </div>
      <button
        onClick={
          () => {
            Cookies.remove('token');
            window.location.href = '/login';
          }
        }
        className={styles.button}>Logout</button>
    </section>
  );
};

export default Sidebar;
