import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsApple } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "./Register.module.css";
import CheckBox from "./Checkbox/CheckBox";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { logo, unsCommunity } from "../../../images/image";

const Register = () => {
  const ref = useParams();
  const navigate = useNavigate();

  const [lockRef, setLockRef] = useState(false);

  useEffect(() => {
    if (ref.ref) {
      setReferrer(ref.ref);
      setLockRef(true);
    } else {
      setReferrer("UNS");
      setLockRef(true);
    }
  }, []);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [wallet, setWallet] = useState("");
  const [telegram, setTelegram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [referrer, setReferrer] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [agreeTermsAndCondition, setAgreeTermsAndCondition] = useState(true);
  // height

  const submitHandler = async (e) => {
    if (password !== repeatPassword) {
      alert("Password and Repeat Password should be same");
      return;
    }

    if (!agreeTermsAndCondition) {
      alert("Please agree to the terms and condition");
      return;
    }

    if (
      !email ||
      !username ||
      !wallet ||
      !telegram ||
      !twitter ||
      !referrer ||
      !password ||
      !repeatPassword
    ) {
      alert("Please fill all the fields");
      return;
    }

    if (!email.includes("@")) {
      alert("Please enter valid email");
      return;
    }
    e.preventDefault();

    // let api = process.env.API_URL;
    let api = process.env.REACT_APP_API_URL;
    let path = "/auth/signup";

    let body = {
      email,
      username,
      wallet,
      telegram,
      twitter,
      referrer,
      password,
    };

    try {
      let { data } = await axios.post(api + path, body);
      console.log(data);
      let token = data.token;
      Cookies.set("token", token);
      navigate("/dashboard");
      alert("Registered Successfully");
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
      return;
    }
  };

  return (
    <div className={[styles.wrapper, "mainWrapper"].join(" ")}>
      <a
        href="https://uns.technology/"
        target="_blank"
        rel="noreferrer"
        className={styles.unsCommunityContainer}
      >
        <img src={unsCommunity} className={styles.unsCommunity} alt="#" />
      </a>
      <div>
        <div className={styles.registerContainer}>
          <img src={logo} alt="#" className={styles.logo} />
          <form className={styles.form} onSubmit={submitHandler}>
            {" "}
            <div>
              <h3 className={styles.title}>Welcome to UNS Community</h3>
              {/* <p className={styles.tagline}>Welcome to UNS Community</p> */}
            </div>
            {/* <div className={styles.registerWithContainer}>
            <button className={styles.registerWithButton}>
              <FcGoogle className={[styles.aplleIcon].join(" ")} />
              <p className={styles.registerWithText}>Sign in with Google</p>
            </button>
            <button className={styles.registerWithButton}>
              <BsApple className={[styles.aplleIcon].join(" ")} />
              <p className={styles.registerWithText}>Sign in with Google</p>
            </button>
          </div> */}
            {/* <p className={styles.orWithEmail}>
            <span>SIGNUP</span>
          </p> */}
            <div className={styles.inputWrapper}>
              <input
                type="email"
                name="eamil"
                placeholder="Email"
                className={styles.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />{" "}
              <input
                type="text"
                name="username"
                placeholder="Username"
                className={styles.input}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />{" "}
              <input
                type="text"
                name="wallet"
                placeholder="Wallet Address"
                className={styles.input}
                value={wallet}
                onChange={(e) => setWallet(e.target.value)}
              />{" "}
              <input
                type="text"
                name="telegram"
                placeholder="Telegram Username"
                className={styles.input}
                value={telegram}
                onChange={(e) => setTelegram(e.target.value)}
              />{" "}
              <input
                type="text"
                name="twitter"
                placeholder="Twitter Username"
                className={styles.input}
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
              />{" "}
              <input
                type="text"
                name="referrer"
                placeholder="Referrer"
                className={styles.input}
                value={referrer}
                disabled={lockRef ? true : false}
                onChange={(e) => setReferrer(e.target.value)}
              />{" "}
              <div className={styles.passwordContainer}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  className={[styles.input, styles.passwordInput].join(" ")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {showPassword ? (
                  <AiOutlineEye
                    className={styles.passwordEye}
                    onClick={() => setShowPassword((prev) => !prev)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className={styles.passwordEye}
                    onClick={() => setShowPassword((prev) => !prev)}
                  />
                )}
              </div>
            </div>
            <div className={styles.lineWrapper}>
              <div className={styles.line}></div>
              <div className={styles.line}></div>
              <div className={styles.line}></div>
              <div className={styles.line}></div>
            </div>
            <p className={styles.info}>
              Use 8 or more characters with a mix of letters, numbers & symbols.
            </p>
            <input
              type="password"
              name="repeatPassword"
              placeholder="Repeat Password"
              className={styles.input}
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
            />
            {/* <CheckBox
            checked={agreeTermsAndCondition}
            setChecked={setAgreeTermsAndCondition}
          /> */}
            <button
              className={styles.button}
              // onClick={() => navigate("/default")}
            >
              SIGN UP
            </button>
          </form>
          <p className={styles.notRegisterYet}>
            Already have an Account?{" "}
            <Link to="/login" className={styles.highlightedText}>
              {" "}
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
