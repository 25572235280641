import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Footer.module.css";
const Footer = () => {
  const footerItems = [
    // { name: "About", to: "/about" },
   
    { name: "UNS Official Website", to: "https://uns.technology" },
    { name: "Support", to: "mailto:support@uns.technology" },
    // { name: "Contact Us", to: "/contactUs" },
  ];
  return (
    <div className={styles.footer}>
      <p className={styles.text}>© 2023 Copyrights Reserved</p>
      <div className={styles.items}>
        {footerItems.map((el, i) => (
          <NavLink to={el.to} className={styles.text} key={i}>
            {el.name}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Footer;
