import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { BsApple } from "react-icons/bs";
// import { FcGoogle } from "react-icons/fc";
import styles from "./ResetPassword.module.css";
// import axios from "axios";
// import Cookies from "js-cookie";
import { logo, unsCommunity } from "../../../images/image";
import axios from "axios";

const ResetPassword = ({ setuUser }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [showSendCodeComponent, setShowSendCodeComponent] = useState(false);
  const [code, setCode] = useState("");
  const [showNewPasswordComponent, setShowNewPasswordComponent] =
    useState(false);
  const [newPassword, setNewPassword] = useState("");

  async function sendcodehandler(e) {
    e.preventDefault();
    if (!email) return alert("Please enter email");
    if (!email.includes("@")) return alert("Please enter valid email");

    let api = "https://community-api.uns.technology";
    let path = "/auth/getResetToken";
    let url = api + path;
    const { data, status } = await axios.post(url, { email });
    if (status === 200) {
      alert("Code sent successfully");
      setShowSendCodeComponent(true);
    }
  }

  async function verifyCodeHandler(e) {
    e.preventDefault();
    if (!code) return alert("Please enter code");
    let api = "https://community-api.uns.technology";
    let path = "/auth/validateResetToken";
    let url = api + path;
    const { data, status } = await axios.post(url, {
      email,
      resetToken: code,
    });
    if (status === 200) {
      setShowNewPasswordComponent(true);
      setShowSendCodeComponent(false);
    }
  }

  async function completeHandler(e) {
    e.preventDefault();
    if (!newPassword) return alert("Please enter new password");
    let api = "https://community-api.uns.technology";
    let path = "/auth/resetPassword";
    let url = api + path;
    const { data, status } = await axios.post(url, {
      email,
      resetToken: code,
      password: newPassword,
    });
    if (status === 200) {
      alert("Password reset successfully");
      navigate("/login");
      setShowSendCodeComponent(true);
    }
  }

  return (
    <div className={[styles.wrapper, "mainWrapper"].join(" ")}>
      <a  target="_blank" rel="noreferrer">
        <img src={unsCommunity} className={styles.unsCommunity} alt="#" />
      </a>
      <div className={styles.resetPasswordContainer}>
        <img src={logo} alt="#" className={styles.logo} />
        <form className={styles.form}>
          {" "}
          <div>
            <h3 className={styles.title}>Welcome to UNS Community </h3>
          </div>
          {!showSendCodeComponent && !showNewPasswordComponent && (
            <div className={styles.inputWrapper}>
              <input
                type="email"
                name="eamil"
                placeholder="Email"
                className={styles.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />{" "}
              <button className={styles.button} onClick={sendcodehandler}>
                Send Code
              </button>
              {/* <input
              type="password"
              name="password"
              placeholder="Password"
              className={styles.input}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            /> */}
            </div>
          )}
          {showSendCodeComponent && !showNewPasswordComponent && (
            <div className={styles.inputWrapper}>
              <input
                type="text"
                name="code"
                placeholder="Enter the code"
                className={styles.input}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <button className={styles.button} onClick={verifyCodeHandler}>
                Verify
              </button>
            </div>
          )}
          {!showSendCodeComponent && showNewPasswordComponent && (
            <div className={styles.inputWrapper}>
              <input
                type="password"
                name="password"
                placeholder="Enter new password"
                className={styles.input}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <button className={styles.button} onClick={completeHandler}>
                Complete
              </button>
            </div>
          )}
        </form>
        <p className={styles.notLoginYet}>
          Not a Member yet?{" "}
          <Link to="/register" className={styles.highlightedText}>
            {" "}
            Register
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
