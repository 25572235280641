import React from "react";
import Balance from "./Balance/Balance";
import WithDraw from "./WithDraw/WithDraw";
import styles from "./BalanceAndWithdraw.module.css";

const BalanceAndWithdraw = ({ uuser }) => {
  return (
    <div className={styles.wrapper}>
      <Balance uuser={uuser} />
      {/* <WithDraw /> */}
    </div>
  );
};

export default BalanceAndWithdraw;
