import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { TbArrowsUpDown } from "react-icons/tb";
import { CgSortAz } from "react-icons/cg";
import { FaCircle } from "react-icons/fa";
import styles from "./LeaderBoard.module.css";
import { kateMorrisson } from "../../images/image";
import Paginations from "./Pagination/Pagination";
import axios from "axios";
const LeaderBoard = () => {
  const [data, setData] = useState([]);

  let api = "https://community-api.uns.technology/leaderboard";
  const getData = async () => {
    try {
      const { data } = await axios.get(api);
      console.log(data.res.length, "data length");
      setData(data.res);

    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  // filtering
  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = data?.filter(
    (el) =>
      el.user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      el.rank == +searchQuery
  );

  //   pagination
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 90000;
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // formatin number

  return (
    <div className={styles.leaderBoard}>
      <h3 className={styles.title}>Leaderboard</h3>
      <div className={styles.filteringSection}>
        <div className={styles.left}>
          {" "}
          {/* <CgSortAz className={styles.sortIcon} /> */}
          {/* <div className={styles.upDownContainer}>
            <TbArrowsUpDown className={styles.icon} />
          </div> */}
          {/* <p className={styles.text}>See the list of all participants</p> */}
        </div>
        <div className={styles.searchContainer}>
          <FiSearch className={styles.searchIcon} />
          <input
            type="text"
            value={searchQuery}
            className={styles.input}
            placeholder="Enter Username"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <tbody>
            <tr className={[styles.row, styles.header].join(" ")}>
              <th className={styles.heading}>Rank</th>
              <th className={styles.heading}>User </th>
              <th className={styles.heading}>Level 1</th>
              <th className={styles.heading}>Level 2 </th>
              <th className={styles.heading}>Level 1 Earnings</th>
              <th className={styles.heading}>Level 2 Earnings</th>
              <th className={styles.heading}>Total Earnings</th>
            </tr>
            {currentItems.map((el, index) => (
              <tr className={styles.row} key={index}>
                <td className={[styles.item, styles.rank].join(" ")}>
                  #{index + 1}
                </td>
                <td className={styles.item}>
                  <div className={styles.flex}>
                    {" "}
                    <img
                      src={el.user.image}
                      alt="#"
                      className={styles.userImage}
                    />
                    <span>{el.user.name}</span>
                  </div>
                </td>
                <td className={[styles.complete, styles.item].join(" ")}>
                  <div className={styles.flex}>{el.level1}</div>
                </td>
                <td className={[styles.complete, styles.item].join(" ")}>
                  <div className={styles.flex}>{el.level2}</div>
                </td>
                <td className={[styles.complete, styles.item].join(" ")}>
                  {el.level1Earning}
                </td>
                <td className={[styles.complete, styles.item].join(" ")}>
                  {el.level2Earning}
                </td>
                <td className={[styles.complete, styles.item].join(" ")}>
                  {el.totalEarning}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* <Paginations
        itemsPerPage={itemsPerPage}
        totalItemsCount={data.length}
        activePage={activePage}
        setActivePage={setActivePage}
      /> */}
    </div>
  );
};
export default LeaderBoard;
