import React from "react";
import styles from "./RefferelProgramAndMothelyInvitationVsCompletedTask.module.css";
import MonthlyInvitationVsCompletedTask from "./MonthlyInvitationVsCompletedTask/MonthlyInvitationVsCompletedTask";
import RefferelProgram from "./RefferelProgram/RefferelProgram";

const RefferelProgramAndMothelyInvitationVsCompletedTask = () => {
  return (
    <div className={styles.wrapper}>
      <RefferelProgram />
      {/* <MonthlyInvitationVsCompletedTask /> */}
    </div>
  );
};

export default RefferelProgramAndMothelyInvitationVsCompletedTask;
