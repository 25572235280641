import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import RefferelProgramAndMothelyInvitationVsCompletedTask from "../../Component/Default/RefferelProgramAndMothelyInvitationVsCompletedTask/RefferelProgramAndMothelyInvitationVsCompletedTask";
import BalanceAndWithdraw from "../../Component/Default/BalanceAndWithdraw/BalanceAndWithdraw";
import RefferedUser from "../../Component/Default/RefferedUser/RefferedUser";
import styles from "./Default.module.css";
import GenerateIncome from "../../Component/Default/GenerateIncome/GenerateIncome";
import Cookies from "js-cookie";
import axios from "axios";
import { useState } from "react";

const Default = ({ setuUser, uuser }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});

  let token = Cookies.get("token");
  if (!token) {
    navigate("/login");
  }

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getProfile = async () => {
    const api = "https://community-api.uns.technology/account";
    try {
      const { data } = await axios.get(api, config);
      console.log(data);
      let user = data.user;
      setuUser(user);
      setUser(user);
    } catch (error) {
      console.log(error);
      navigate("/login");
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <section className={styles.wrapper}>
        <BalanceAndWithdraw user={user} />
        <RefferelProgramAndMothelyInvitationVsCompletedTask />
        {/* <RefferedUser uuser={uuser} /> */}
      </section>
      <section>
        <GenerateIncome user={user} />
      </section>
    </>
  );
};

export default Default;
