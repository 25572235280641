import React, { useState } from "react";
import { AiOutlineDollar, AiOutlineEye } from "react-icons/ai";
import { HiOutlineUserGroup } from "react-icons/hi";
import styles from "./Balance.module.css";

const Balance = ({ user }) => {
  console.log(user)
  localStorage.setItem("username", user.username)
  const [acitveBalanceBox, setActiveBalanceBox] = useState(0);
  const balance = [
    { title: "My Balance ", icon: <AiOutlineDollar />, balance: user.Balance + " UNS" },
    { title: "Pending Balance", icon: <AiOutlineEye />, balance: user.pendingBalance + " UNS" },
    { title: "Total Withdrawn", icon: <AiOutlineEye />, balance: user.totalWithdrawn + " UNS" },
    {
      title: "Total Referral Earnings",
      icon: <HiOutlineUserGroup />,
      balance: user.totalReferralEarnings + " UNS",
    },
    { title: "Level 1 Count", icon: <AiOutlineEye />, balance: user.level1count },
    { title: "Level 2 Count", icon: <AiOutlineEye />, balance: user.level2count },
    // { title: "Level 2 count", icon: <AiOutlineEye />, balance: user.level2count },

  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.balanceContainer}>
        {balance.map((el, i) => (
          <div
            className={[
              styles.balanceBox,
              acitveBalanceBox === i && styles.activeBalanceBox,
            ].join(" ")}
            key={i}
            onClick={() => {
              setActiveBalanceBox(i);
            }}
          >
            <div className={styles.heading}>
              <h4 className={styles.title}>{el.title}</h4>
              <p className={styles.icon}>{el.icon}</p>
            </div>
            <p className={styles.balance}>{(el.balance)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Balance;
