import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { TbArrowsUpDown } from "react-icons/tb";
import { CgSortAz } from "react-icons/cg";
import styles from "./GenerateIncome.module.css";
import SingleTask from "./SingleTask/SingleTask";
import axios from "axios";

const GenerateIncome = ({ user }) => {
  const [searchQuery, setSearchQuery] = useState("");


  let api = "https://community-api.uns.technology/task/tasks";

  const [tasks, setTasks] = useState([]);

  let getTasks = async () => {
    const { data } = await axios.get(api);
    console.log(data.tasks);
    setTasks(data.tasks);
  }

  useEffect(() => {
    getTasks();
  }, []);

  const allTask = [
    {
      to: "https://web.telegram.org/",
      name: "Arlene McCoy",
      userName: "@arleenmccy",
      info: "Lorem Ipsum, also known as dummy text, is a placeholder text used in the printing, typesetting, and grapdesign industries typesetting ",
      joined: false,
    },
    {
      to: "https://web.telegram.org/",
      name: "Arlene McCoy",
      userName: "@arleenmccy",
      info: "Lorem Ipsum, also known as dummy text, is a placeholder text used in the printing, typesetting, and grapdesign industries typesetting ",
      joined: false,
    },
    {
      to: "https://web.telegram.org/",
      name: "Arlene McCoy",
      userName: "@arleenmccy",
      info: "Lorem Ipsum, also known as dummy text, is a placeholder text used in the printing, typesetting, and grapdesign industries typesetting ",
      joined: false,
    },
    {
      to: "https://web.telegram.org/",
      name: "Arlene McCoy",
      userName: "@arleenmccy",
      info: "Lorem Ipsum, also known as dummy text, is a placeholder text used in the printing, typesetting, and grapdesign industries typesetting ",
      joined: false,
    },
    {
      to: "https://web.telegram.org/",
      name: "Arlene McCoy",
      userName: "@arleenmccy",
      info: "Lorem Ipsum, also known as dummy text, is a placeholder text used in the printing, typesetting, and grapdesign industries typesetting ",
      joined: false,
    },
    {
      to: "https://web.telegram.org/",
      name: "Arlene McCoy",
      userName: "@arleenmccy",
      info: "Lorem Ipsum, also known as dummy text, is a placeholder text used in the printing, typesetting, and grapdesign industries typesetting ",
      joined: false,
    },
    {
      to: "https://web.telegram.org/",
      name: "Arlene McCoy",
      userName: "@arleenmccy",
      info: "Lorem Ipsum, also known as dummy text, is a placeholder text used in the printing, typesetting, and grapdesign industries typesetting ",
      joined: true,
    },
    {
      to: "https://web.telegram.org/",
      name: "Arlene McCoy",
      userName: "@arleenmccy",
      info: "Lorem Ipsum, also known as dummy text, is a placeholder text used in the printing, typesetting, and grapdesign industries typesetting ",
      joined: true,
    },
  ];
  const filteredTasks = tasks?.filter(
    (task) =>
      task.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      task.description.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div className={styles.generateIncome}>
      <h4 className={styles.title}>Tasks</h4>

      {/* <div className={styles.filteringSection}>
        <div className={styles.left}>
          {" "}
          <CgSortAz className={styles.sortIcon} />
          <div className={styles.upDownContainer}>
            <TbArrowsUpDown className={styles.icon} />
          </div>
          <p className={styles.taskLeft}>
            There are {allTask.filter((task) => !task.joined).length} tasks left
          </p>
        </div>
        <div className={styles.searchContainer}>
          <FiSearch className={styles.searchIcon} />
          <input
            type="text"
            value={searchQuery}
            className={styles.input}
            placeholder="To find, enter name or ID"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div> */}

      <div className={styles.taskContainer}>
        {filteredTasks.map((el, i) => (
          <SingleTask {...el} key={i} user={user} />
        ))}
      </div>
    </div>
  );
};

export default GenerateIncome;
