import React from "react";
import { FaTelegramPlane } from "react-icons/fa";
import styles from "./SingleTask.module.css";
import { click } from "@testing-library/user-event/dist/click";
import axios from "axios";
import { useState } from "react";
import Cookies from "js-cookie";
const SingleTask = ({ to, name, title, userName, url, icon, joined, description, user }) => {
  let token = Cookies.get("token");
  console.log(user)

  let completedTasks = user?.completedTasks;



  const [clicked, setClicked] = useState(false);
  async function addTask(_task) {
    let api = "https://community-api.uns.technology/account/completeTask";
    const { data } = await axios.post(
      api,
      { task: _task }
      , {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
    alert(data.message);
  }

  function button() {
    if (completedTasks?.includes(title)) {
      return {
        title: 'Completed',
        func: () => {
          alert("You have already joined this task");
        }
      }
    }
    if (!clicked) {
      return {
        title: 'Complete this Task',
        func: async () => {
          setClicked(true);
          window.open(url, "_blank");
          await addTask(title);
          completedTasks.push(title);
          window.location.reload();
        }
      }
    }

    if (clicked) {
      return {
        title: 'Complete Task',
        func: async () => {

          await addTask(title);
          completedTasks.push(title);
          window.location.reload();
        }
      }
    }

    if (joined) {
      return {
        title: 'Joined',
        func: () => {
          alert("You have already joined this task");
        }
      }
    }
  }
  return (
    <div className={[styles.task, joined && styles.alreadyJoined].join(" ")}>
      <div className={styles.header}>
        <a
          href={to}
          target="_blank"
          rel="noreferrer"
          className={styles.iconContainer}
        >
          {/* <FaTelegramPlane className={styles.icon} /> */}
          {<img src={icon} width={25} alt="icon" className={styles.icon} />}
        </a>
        <div className={styles.nameAndUserName}>
          <p className={styles.name}>{name}</p>
          <p className={styles.userName}>{userName}</p>
        </div>
      </div>
      <div className={styles.infoContainer}>
        <p className={styles.info}>{description}</p>
      </div>
      <button
        onClick={button().func}
        className={[styles.button, joined && styles.diableButton].join(" ")}
      >
        {button().title}
      </button>
    </div>
  );
};

export default SingleTask;
