import { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Header from "./Component/Header/Header";
import Default from "./pages/Default/Default";
import Login from "./pages/LoginRegisterResetPasword/Login/Login";
import Register from "./pages/LoginRegisterResetPasword/Register/Register";
import Footer from "./Component/Footer/Footer";
import LeaderBoardPage from "./pages/LeaderBoardPage/LeaderBoardPage";
import WithDrawal from "./pages/WithDrawal/WithDrawal";
import Cookies from "js-cookie";
import ResetPassword from "./pages/LoginRegisterResetPasword/ResetPassword/ResetPassword";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [uuser, setUser] = useState({});

  let token = Cookies.get("token");
  const hideNavbar =
    location.pathname === "/login" ||
    location.pathname === "/register" ||
    location.pathname === "/reset-pass" ||
    location.pathname === "";
  // useEffect(() => {
  //   if (token) {
  //     navigate("/dashboard");
  //   } else {
  //     navigate("/login");
  //   }
  // }, []);

  if (location.pathname == "/") {
    navigate("/dashboard");
  }
  console.log(location);

  return (
    <>
      {" "}
      {!hideNavbar && (
        <div className="mainContainer">
          <div className="mainWrapper">
            <Header route={location.pathname.substring(1)} />
            <Routes>
              <Route path="/register/:ref" element={<Register />} />{" "}
              <Route path="/" element={<Login setuUser={setUser} />} />{" "}
              <Route
                path="/dashboard"
                element={<Default uuser={uuser} setuUser={setUser} />}
              />
              <Route path="/leaderBoard" element={<LeaderBoardPage />} />
              <Route path="/withdraw" element={<WithDrawal uuser={uuser} />} />
            </Routes>
            <Footer />
          </div>
        </div>
      )}
      <Routes>
        <Route path="/login" element={<Login setuUser={setUser} />} />
        <Route path="/reset-pass" element={<ResetPassword />} />{" "}
        <Route path="/register" element={<Register />} />{" "}
      </Routes>
    </>
  );
}

export default App;
