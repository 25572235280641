import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import styles from "./MonthlyInvitationVsCompletedTask.module.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

let options = {
  responsive: true,
  aspectRatio: false,

  animations: {
    tension: {
      duration: 1000,
      easing: "linear",
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
    bar: {
      backgroundColor: (context) => {
        const index = context.dataIndex; // Get the index of the data point
        const datasetIndex = context.datasetIndex; // Get the index of the dataset
        const color1 = "rgba(168, 204, 158, .6)";
        const color2 = "rgba(168, 204, 158, 1)";
        return index < 6 ? color1 : color2; // Use color1 for the first 6 data points and color2 for the rest
      },
    },
  },
  scales: {
    x: {
      ticks: {
        color: "rgba(249, 253, 240, 0.40)",

        font: {
          family: '"Inter", Verdana, Geneva, Tahoma, sans-serif',
          size: 12,
          lineHeight: "1.26",
        },
        padding: 0,
      },
      border: {
        display: false,
      },
      grid: {
        display: false,
        offset: true,
      },
      offset: true,
    },
    y: {
      ticks: {
        color: "rgba(249, 253, 240, 0.40)",

        font: {
          family: '"Inter", Verdana, Geneva, Tahoma, sans-serif',
          size: 12,
          lineHeight: "1.26",
        },
        count: 4,
        padding: 10,
        stepSize: 10, // Set the step size for the ticks
      },
      border: {
        display: false,
      },
      grid: {
        display: true,
        color: "rgb(255 255 255 / 6%)",
      },
    },
  },
};

let data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      data: [
        { x: "Jan", y: 12 },
        { x: "Feb", y: 15 },
        { x: "Mar", y: 18 },
        { x: "Apr", y: 25 },
        { x: "May", y: 18 },
        { x: "Jun", y: 18 },
        { x: "Jan", y: 8 },
        { x: "Feb", y: 10 },
        { x: "Mar", y: 12 },
        { x: "Apr", y: 15 },
        { x: "May", y: 6 },
        { x: "Jun", y: 15 },
      ],
      borderColor: "transparent",
      borderWidth: 1,
      borderRadius: 4,
      barThickness: 20,
    },
  ],
};

const MonthlyInvitationVsCompletedTask = () => {
  return (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>Monthly invitations VS Completed tasks</h4>
      <div className={styles.chartWrapper}>
        <div className={styles.inveChart}>
          <Bar options={options} data={data} />
        </div>
      </div>
    </div>
  );
};

export default MonthlyInvitationVsCompletedTask;
