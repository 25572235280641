import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsApple } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import styles from "./Login.module.css";
import axios from "axios";
import Cookies from "js-cookie";
import { logo, unsCommunity } from "../../../images/image";

const Login = ({ setuUser }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const getProfile = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const api = "https://community-api.uns.technology/account";
    try {
      const { data } = await axios.get(api, config);
      console.log(data);
      let user = data.user;
      setuUser(user);
    } catch (error) {
      console.log(error);
      navigate("/login");
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    let api = "https://community-api.uns.technology/auth/login";

    let body = {
      email,
      password,
    };

    try {
      let { data } = await axios.post(api, body);
      if (data) {
        Cookies.set("token", data.token);
        console.log(data);
        navigate("/dashboard");
      } else {
        console.log(data);
        alert(data.message);
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    }
  };

  return (
    <div className={[styles.wrapper, "mainWrapper"].join(" ")}>
      <a  target="_blank" rel="noreferrer">
        <img src={unsCommunity} className={styles.unsCommunity} alt="#" />
      </a>
      <div className={styles.loginContainer}>
        <img src={logo} alt="#" className={styles.logo} />
        <form className={styles.form} onSubmit={submitHandler}>
          {" "}
          <div>
            <h3 className={styles.title}>Welcome to UNS Community </h3>
            {/* <p className={styles.tagline}>Your Social Campaigns</p> */}
          </div>
          {/* <div className={styles.loginWithContainer}>
            <button className={styles.loginWithButton}>
              <FcGoogle className={[styles.aplleIcon].join(" ")} />
              <p className={styles.loginWithText}>Sign in with Google</p>
            </button>
            <button className={styles.loginWithButton}>
              <BsApple className={[styles.aplleIcon].join(" ")} />
              <p className={styles.loginWithText}>Sign in with Google</p>
            </button>
          </div> */}
          <div className={styles.inputWrapper}>
            <input
              type="email"
              name="eamil"
              placeholder="Email"
              className={styles.input}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />{" "}
            <input
              type="password"
              name="password"
              placeholder="Password"
              className={styles.input}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <p
              onClick={() => navigate("/reset-pass")}
              className={[styles.highlightedText, styles.forgotPassword].join(
                " "
              )}
            >
              Forgot Password?
            </p>
          </div>
          <button
            className={styles.button}
            // onClick={() => navigate("/default")}
          >
            Login
          </button>
        </form>
        <p className={styles.notLoginYet}>
          Not a Member yet?{" "}
          <Link to="/register" className={styles.highlightedText}>
            {" "}
            Register
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
