import React from "react";
import Balance from "./Balance/Balance";
import WithDraw from "./WithDraw/WithDraw";
import styles from "./BalanceAndWithdraw.module.css";

const BalanceAndWithdraw = ({ user }) => {
  return (
    <div className={styles.wrapper}>
      <Balance user={user} />
      {/* <WithDraw /> */}
    </div>
  );
};

export default BalanceAndWithdraw;
