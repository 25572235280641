import React, { useState } from "react";
import { HiOutlineClipboardList } from "react-icons/hi";
import CopyToClipboard from "react-copy-to-clipboard";

import styles from "./RefferelProgram.module.css";
import { refferel } from "../../../../images/image";

const RefferelProgram = () => {
  const [showCopiedLabel, setShowCopiedLabel] = useState(false);
  const showCopiedLabelHandler = () => {
    setShowCopiedLabel(true);
    setTimeout(() => setShowCopiedLabel(false), 1000);
  };
  return (
    <div className={styles.referelProgramWrapper}>
      <h4 className={styles.title}>Your Referral invite link</h4>

      <div className={styles.linkContainer}>
        <p className={styles.link}>
          https://community.uns.technology/register/
          {localStorage.getItem("username")}
        </p>

        <CopyToClipboard
          text={`https://community.uns.technology/register/${localStorage.getItem(
            "username"
          )}`}
        >
          <HiOutlineClipboardList
            className={styles.icon}
            onClick={showCopiedLabelHandler}
          />
        </CopyToClipboard>
        {showCopiedLabel && <span className={styles.copied}>Copied</span>}
      </div>
      <div className={styles.textAndImage}>
        <p className={styles.text}>
          Refer your friends using your invite link, for every referrer who
          signup and complete all task you will get 2 UNS as Rewards for Level 1
          and 1 UNS as Rewards for Level 2.
        </p>
        <img src={refferel} alt="#" className={styles.image} />
      </div>
    </div>
  );
};

export default RefferelProgram;
