import React, { useState } from "react";

import { AiOutlineAlignRight } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import Sidebar from "./Sidebar/Sidebar";

import styles from "./Header.module.css";

const Header = ({ route }) => {
  const [sidebar, setSidebar] = useState(false);

  return (
    <>
      <Sidebar sidebar={sidebar} setSidebar={setSidebar} />
      <div className={styles.header}>
        <h3 className={styles.route}>
          Home / <span className={styles.highlight}>{route}</span>{" "}
        </h3>

        <div className={styles.iconContainer}>
          {sidebar ? (
            <MdOutlineClose
              className={styles.icon}
              onClick={() => setSidebar((prev) => !prev)}
            />
          ) : (
            <AiOutlineAlignRight
              className={styles.icon}
              onClick={() => setSidebar((prev) => !prev)}
            />
          )}

          {/* {sidebar ? (
            <MdOutlineClose
              className={styles.hamburger}
              onClick={() => setSidebar((prev) => !prev)}
            />
          ) : (
            <AiOutlineAlignRight
              className={styles.hamburger}
              onClick={() => setSidebar((prev) => !prev)}
            />
          )} */}
        </div>
      </div>
    </>
  );
};

export default Header;
