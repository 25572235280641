import React, { useState } from "react";
import { AiOutlineDollar, AiOutlineEye } from "react-icons/ai";
import { HiOutlineUserGroup } from "react-icons/hi";
import styles from "./Balance.module.css";

const Balance = ({ uuser }) => {
  const [acitveBalanceBox, setActiveBalanceBox] = useState(0);
  const balance = [
    { title: "My Balance", icon: <AiOutlineDollar />, balance: uuser.Balance },
    { title: "Pending Balance", icon: <AiOutlineEye />, balance: uuser.pendingBalance },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.balanceContainer}>
        {balance.map((el, i) => (
          <div
            className={[styles.balanceBox].join(" ")}
            key={i}
            onClick={() => {
              setActiveBalanceBox(i);
            }}
          >
            <div className={styles.heading}>
              <h4 className={styles.title}>{el.title}</h4>
              <p className={styles.icon}>{el.icon}</p>
            </div>
            <p className={styles.balance}>{(el.balance)} UNS</p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Balance;
